import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "page-number-info"
};
import { ref, reactive, onMounted, nextTick } from "vue";
export default {
  __name: 'PageNumberInfo',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default")]);
    };
  }
};